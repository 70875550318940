/* GENERAL CSS */
* {
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
  box-sizing: border-box;
  
  /* border: 1px solid blue; */ }
html {
  scroll-behavior: smooth;
}
body::-webkit-scrollbar {
  width: 0.45rem; 
}

body::-webkit-scrollbar-track {
  background: #111111;
}

body::-webkit-scrollbar-thumb {
  background: #3e3e3e;
  border-radius: 20px;
}
body::-webkit-scrollbar-thumb:hover {
  background: #d291fb;
}

#particles canvas {
  z-index: -1;
  width: 100%;
  position: absolute;
  background: none !important;
}

@font-face {
  font-family: lalezar;
  src: url(../src/assets/fonts/Lalezar-Regular.ttf);
}
