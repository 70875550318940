body {
  margin: 0;
 background: rgb(91,101,243);
  /* background: linear-gradient(0deg, rgba(91,101,243,1) 0%, rgba(210,145,251,1) 100%); */
  /* background-image: linear-gradient(180deg, rgb(7,38,125),rgb(49,63,139),rgb(24,30,49)); */
  background-image: linear-gradient(180deg, rgb(87 100 149), rgb(36 47 86), rgb(24, 30, 49));
  /* background: rgb(210,145,251);
  background: linear-gradient(180deg, rgba(210,145,251,1) 0%, rgba(91,101,243,1) 100%);  */
  /* background-repeat: repeat;
  background-attachment: fixed;  */
/* background-image: url('./assets/img/body-bg.png'); */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: TheDeadSaloon;
  src: url('./assets/fonts/thedeadsaloon-Regular.ttf');
}

@font-face {
  font-family: Bronco;
  src: url('./assets/fonts/BroncoPersonalUse.ttf');
}

