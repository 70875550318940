@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
}
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: rgb(91,101,243);
  background-image: linear-gradient(180deg, rgb(87 100 149), rgb(36 47 86), rgb(24, 30, 49));
  background-repeat: repeat;
  background-attachment: fixed;
}
.loader-img__spin {
  width: 150px;
  animation: rotate-center 1.8s ease-in-out infinite both;
}